import React from "react";
import Slider from "react-slick";

import { Images } from "../utils/images";

const CarouselItems = ({ rtl }) => {
  
  // var options = {
  //   slidesToShow: 9,
  //   slidesToScroll: 5,
  //   centerMode: true,
  //   centerPadding: "30px",
  //   autoplay: true,
  //   infinite: true,
  //   autoplaySpeed: 0,
  //   arrows: false,
  //   cssEase: "linear",
  //   rtl: rtl,
  // };

  let settings = {
    slidesToShow: 9,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0,
  initialSlide: 0,
  speed: 5000,
  pauseOnHover: false,
  cssEase: 'linear',
  infinite: true,
  rtl,
  }
  return (
    <div className="CarouselItems">
      
      <Slider {...settings}>
        {Images.map((image) => (
          <img src={`/images/gallery/${image.image}`} alt="" className="p-2" />
        ))}
      </Slider>
     
    </div>
  );
};

export default CarouselItems;
