import React from "react";

const Roadmap = () => {
  const content = [
    {
      title: "- LAUNCH -",
      des: "4444 Future Punkz Genesis will be released to the blockchain.",
      borderColor: "borderOne",
    },
    {
      title: "- 7K GIVEAWAY - ",
      des: "We are giving away 7k to one of our holders in a live raffle-party.",
      borderColor: "borderTwo",
    },
    {
      title: "- DONATION -",
      des: "Let’s make a donation from the team wallet to an organization of your choice.",
      borderColor: "borderTwo",
    },
    
    {
      title: "- MERCH -",
      des: "Merch is merch. Let’s make something dope",
      borderColor: "borderTwo",
    },
    {
      title: "- V2  LAUNCH-",
      des: "V2 collection for the DAO by the DAO",
      borderColor: "borderTwo",
    },
    {
      title: "- FUTOPIA ROADMAP 2.0 -",
      des: " Metaverse - launchpad - IRL events The future is DAO!",
      borderColor: "borderOne",
    },
  ];
  return (
    <div className="Roadmap" id="roadmapSection">
      <div className="container">
        <h1 className="roadmap-heading">Roadmap</h1>
        <p className="subText">
          First stop moon. Second stop jupiter. Third stop FUTOPIA!
        </p>
        <div className="roadmapCard">
          {content.map((item) => (
            <div className={item.borderColor}>
              <h5 className="roadmap-title">{item.title}</h5>
              <p className="subText">{item.des}</p>
            </div>
          ))}
        </div>
        <p className="roadmap-paragraph">
          After we sold out, the DAO will be completely run by the community.
          The roadmap represents a few ideas and milestones. The most important
          is: We will decide together were we go with the roadmap and the
          treasury! 
        </p>
        <p className="roadmap-paragraph"> The Future is DAO </p>
        {/* <p>UTOPIA</p> */}
      </div>
    </div>
  );
};

export default Roadmap;
