export  const Images = [
    {
      image: "1.png",
    },
    {
      image: "2.png",
    },
    {
      image: "3.png",
    },
    {
      image: "4.png",
    },
    {
      image: "5.png",
    },
    {
      image: "6.png",
    },
    {
      image: "7.png",
    },
    {
      image: "8.png",
    },
    {
      image: "9.png",
    },
    {
      image: "10.png",
    },
    {
      image: "11.png",
    },
    {
        image: "12.png",
      },
      {
        image: "13.png",
      },
      {
        image: "14.png",
      },
      {
        image: "15.png",
      },
      {
        image: "16.png",
      },
      {
        image: "17.png",
      },
      {
        image: "18.png",
      },
      {
        image: "19.png",
      },
      {
        image: "20.png",
      },
      {
        image: "21.png",
      },
      {
        image: "22.png",
      },
      {
        image: "23.png",
      },
      {
        image: "24.png",
      },
      {
        image: "25.png",
      },
      {
        image: "26.png",
      },
      {
        image: "27.png",
      },
      {
        image: "28.png",
      },
      {
        image: "29.png",
      },
      {
        image: "30.png",
      },
      {
        image: "31.png",
      },
  ];